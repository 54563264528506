import { cssContainer } from "@/game-lol/components/in-game-template-arena-god-champs.style.mjs";
import * as Crown from "@/inline-assets/crown.svg";
import html from "@/util/html-template.mjs";

export const arenaGodChamps = html/* html */ `
  <div class="${cssContainer()}">
    <header>
      <div class="crown-icon">${Crown.svg}</div>
      <div>
        <h4 class="section-title type-title--bold">{{title}}</h4>
        <p class="section-description type-caption--semi">{{description}}</p>
      </div>
    </header>
    <ol class="list">
      {{#list}}
      <li style="{{style}}" data-empty="{{empty}}">
        <img width="28" height="28" class="champ-img" src="{{image}}" />
        <div class="check-icon">{{{checkIcon}}}</div>
      </li>
      {{/list}}
    </ol>
  </div>
`;
