import { css } from "goober";

export const cssContainer = () => css`
  display: none;
  grid-template-columns: 1fr !important;

  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--sp-3);
    padding: var(--sp-6);
    padding-block-start: var(--sp-3);

    hr {
      display: block;
      margin: 0;
      width: 100%;
      height: 1px;
      background-color: var(--shade6);
      border: none;
      margin-block: var(--sp-1);
      mask-image: linear-gradient(
        to right,
        transparent,
        black 25%,
        black 75%,
        transparent 100%
      );
    }
  }

  .group {
    position: relative;
    display: grid;
    grid-auto-rows: max-content;
    gap: var(--sp-1);

    &[data-crowd-favorites="true"] {
      .suggestions-list:not(.crowd-favorites) {
        height: 17rem;
      }
    }

    .title {
      color: var(--shade1);

      :not([data-phase="BANNING"]) & {
        color: var(--blue);
      }
      [data-phase="BANNING"] & {
        color: var(--red);
      }
    }

    .waiting {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: var(--sp-3);
      justify-content: center;
      align-items: center;
      padding-block-start: 2rem;
      inset: 0;

      svg {
        width: 7rem;
        height: 7rem;
      }
    }

    &[data-active="false"] {
      .suggestions-list {
        opacity: 0;
        pointer-events: none;
      }
      .search-container {
        opacity: 0.25;
        pointer-events: none;
      }
    }
    &[data-active="true"] {
      .waiting {
        display: none;
      }
    }
  }

  .search-container {
    width: 100%;
  }

  .suggestions-list {
    display: grid;
    gap: var(--sp-1_5);
    grid-auto-rows: max-content;
    height: 30.25rem;
    padding-block: var(--sp-1);
    overflow-y: scroll;
    mask-image: linear-gradient(to top, transparent, black 7rem);

    &.crowd-favorites {
      height: auto;
      mask-image: none;
    }

    &::-webkit-scrollbar {
      width: 2px;
    }
  }

  .duo {
    .champ-portrait {
      width: var(--sp-7);

      &:nth-child(1) {
        translate: 20% -20%;
      }
      &:nth-child(2) {
        translate: 20% -20%;
        translate: -20% 20%;
        box-shadow: 0 0 0 2px var(--shade8);
      }
    }
  }
`;
