import { css } from "goober";

import { FALLBACK_IMAGE_URL } from "@/app/constants.mjs";

export const cssContainer = () => css`
  padding: 0;

  .cols {
    grid-template-columns: 1fr 2fr;
    border-bottom-left-radius: var(--br);
    border-bottom-right-radius: var(--br);
  }

  &[data-active-tab="builds"] .cols.builds {
    display: grid;
  }
  &[data-active-tab="suggestions"] .cols.suggestions {
    display: grid;
  }
  &[data-active-tab="builds-arena"] .arena-selected-build {
    display: block;
  }
  [data-queue-id="1700"] &[data-active-tab="suggestions"] .cols.suggestions,
  [data-queue-id="1710"] &[data-active-tab="suggestions"] .cols.suggestions {
    /* grid-template-columns: 1fr 1fr 1fr; */
    display: block;
  }

  /* Opponent select */
  .opponent-select .selectButton .image {
    background: var(--shade4);
    border-radius: 50%;
    overflow: hidden;

    img[src="${FALLBACK_IMAGE_URL}"] {
      opacity: 0;
    }
  }
  .opponent-select .champ-img {
    width: var(--sp-5);
    background: hsla(var(--shade4-hsl) / 0.5);
    border-radius: 50%;
  }

  .item-image {
    display: block;
    margin: 0;
    aspect-ratio: 1;
    position: relative;
    background: var(--shade8);
    border-radius: var(--radii);

    &[data-is-prismatic="true"] {
      padding: 2px;
      background: linear-gradient(
        135deg,
        #e251ad 0%,
        #efdfde 15%,
        #d665bd 30%,
        #947dce 45%,
        #a5cbad 60%,
        #6bc3ff 75%,
        #dbebff 85%,
        #9f4ed6 100%
      );

      img {
        border: 2px solid var(--shade10);
        border-radius: var(--br-sm);
      }
    }
  }

  .low-role-warning {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, var(--red-15) 30%, transparent);
    color: var(--red);
    padding-block: var(--sp-2);
    padding-inline: var(--sp-4) var(--sp-2);
    box-shadow: inset 0 0 0 1px var(--red-25);

    .side {
      display: flex;
      align-items: center;
      gap: var(--sp-2);
    }
    button {
      display: flex;
      align-items: center;
      grid-gap: var(--sp-0_5);
      gap: var(--sp-0_5);
      background: var(--red-15);
      border-radius: var(--br-sm);
      padding-block: var(--sp-1);
      padding-inline: var(--sp-2) var(--sp-3);
      border: 1px solid var(--red-25);
      cursor: pointer;

      &:hover {
        background: var(--red-25);
      }

      svg {
        width: var(--sp-5);
        height: var(--sp-5);
      }
    }
  }
`;

export const cssHeader = () => css`
  display: flex;
  justify-content: space-between;
  background: var(--shade8);
  padding-inline-end: var(--sp-4);
  border-bottom: 1px solid var(--card-inset-border);

  .tab-list {
    display: flex;
  }
  .tabs-tab {
    position: relative;
    padding: var(--sp-4) var(--sp-2);
    color: var(--shade2);
    background: transparent;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: var(--sp-12);
      height: 3px;
      border-top-left-radius: var(--br-sm);
      border-top-right-radius: var(--br-sm);
      translate: -50% 0;
    }

    &:first-child {
      padding-inline-start: var(--sp-4);
    }
    &:last-child {
      padding-inline-end: var(--sp-4);
    }

    &[data-active="true"],
    &:hover {
      color: var(--shade0);

      .contents {
        background: var(--shade3-25);
        box-shadow: inset 0 0 0 1px var(--shade1-15);
      }
    }
    &[data-active="true"] {
      &::before {
        background: var(--primary);
      }
    }
    &[data-disabled="true"] {
      opacity: 0.38;
      pointer-events: none;
    }

    .contents {
      display: flex;
      align-items: center;
      gap: var(--sp-2);
      background: var(--shade4-15);
      padding: var(--sp-1_5);
      padding-inline-end: var(--sp-3);
      border-radius: var(--br-sm);
      box-shadow: 0 0 0 1px var(--card-inset-border);

      span {
        text-wrap: nowrap;
        white-space: nowrap;
      }
      svg {
        width: var(--sp-6);
        height: var(--sp-6);
      }
    }
  }

  .settings-btn {
    all: unset;
    cursor: pointer;

    &:hover {
      svg {
        color: var(--shade0);
      }
    }

    svg {
      width: var(--sp-6);
      height: var(--sp-6);
      color: var(--shade3);
      transition: color var(--transition);
    }
  }

  .roles {
    display: flex;
    align-items: center;

    &[data-role="top"] .top svg,
    &[data-role="jungle"] .jungle svg,
    &[data-role="mid"] .mid svg,
    &[data-role="adc"] .adc svg,
    &[data-role="support"] .support svg {
      fill: var(--blue);
    }

    > div {
      padding: 0 var(--sp-3);
      margin: 0 1px;
      background: var(--shade6);

      &:first-of-type {
        border-top-left-radius: var(--br-lg);
        border-bottom-left-radius: var(--br-lg);
      }
      &:last-of-type {
        border-top-right-radius: var(--br-lg);
        border-bottom-right-radius: var(--br-lg);
      }

      svg {
        display: block;
        height: var(--sp-8);
        fill: var(--shade2);
      }
    }
  }
`;
