import { css } from "goober";

export const cssContainer = () => css`
  & {
    display: grid;
    gap: var(--sp-2);
    padding: var(--sp-6);
    padding-block-end: 0;
  }
  .section-description {
    color: var(--shade1);
  }

  header {
    display: flex;
    align-items: flex-start;
    gap: var(--sp-2);
  }
  .crown-icon svg {
    width: var(--sp-8);
    height: auto;
    color: var(--gold);
    filter: drop-shadow(
      0px 0px 20px color-mix(in hsl, var(--gold), transparent 29%)
    );
  }

  .list {
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    gap: var(--sp-1);

    li {
      --radius: var(--br-sm);
      position: relative;
      background: var(--shade6-25);
      border-radius: var(--radius);

      &[data-empty="true"] {
        .check-icon {
          display: none;
        }
        img {
          opacity: 0;
        }
      }
    }
    .check-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      color: var(--green);
      translate: 25% 25%;
      z-index: 1;
      background: var(--shade8);
      border-radius: 50%;
      box-shadow: 0 0 0 1px var(--shade8);
    }
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      border-radius: var(--radius);
    }
    svg {
      width: var(--sp-4);
      height: var(--sp-4);
    }
  }
`;
