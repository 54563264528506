import {
  itemList,
  itemLists,
} from "@/game-lol/components/ChampionArena.style.jsx";
import { cssContainer } from "@/game-lol/components/in-game-template-builds-arena.style.mjs";
import html from "@/util/html-template.mjs";

const template = html`
  <div class="arena-selected-build ${cssContainer()}">
    <div class="${itemLists()}">
      {{braveryNotice}} {{#items}}
      <div>
        <span class="type-subtitle--bold build-title">{{title}}</span>
        <ol class="${itemList()}">
          {{#list}}
          <li
            class="item"
            data-is-prismatic="{{isPrismatic}}"
            data-tip="{{tooltip}}"
          >
            <div class="image-container">
              <div class="item-img">
                <img src="{{src}}" width="{{width}}" height="{{height}}" />
              </div>
              <img class="tier-icon" src="{{tierIcon}}" />
            </div>
            <div class="item-stats">
              <span class="type-caption">{{pickRate}}</span>
              <!-- <span class="type-caption">{{topFourRate}}</span> -->
            </div>
          </li>
          {{/list}}
        </ol>
      </div>
      {{/items}}
    </div>
  </div>
`;

export default template;
